import {
  ENDPOINT,
  FEEDBACK_TYPES
} from '../config';
import {
  toggleButtonLoading,
  getBrowser
} from '../utils';

$(function () {
  window.addEventListener('load', function () {
    const sendIssueRequestButton = document.getElementById('issue-report-button');

    if (!sendIssueRequestButton) return;

    sendIssueRequestButton.addEventListener('click', function (e) {
      toggleButtonLoading($(sendIssueRequestButton), true);

      const data = {};

      data.feedback_type = FEEDBACK_TYPES.ISSUE_REPORT;
      data.subject = 'Report the issue';
      data.device_page = window.location.href;
      data.device_browser = getBrowser();
      data.device_os = navigator.platform;

      const prevPage = document.referrer ? 'Previous page: ' + document.referrer + '\n' : '';
      const time = 'Time: ' + new Date() + '\n';
      const screenResolution = 'Screen resolution: ' + screen.width + 'x' + screen.height + '\n';
      const errorType = 'Error type: ' + e.currentTarget.dataset.errorType + '\n';

      data.message = prevPage + time + screenResolution + errorType;

      $.ajax({
        url: ENDPOINT.FEEDBACK,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data)
      })
        .then(function () {
          toggleButtonLoading($(sendIssueRequestButton), false);
          $('#issueRequestSuccess').modal('show');
        }, function () {
          toggleButtonLoading($(sendIssueRequestButton), false);
          $('#issueRequestError').modal('show');
        });
    });
  });
});
